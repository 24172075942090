<template>
  <div class="AccountsManagedByManager">
    <div class="edit-page-table-title">
      {{ accounts.length }} account(s) managed by <b>{{ manager.first_name }}</b
      >:
    </div>
    <DataTable
      :scrollable="true"
      :value="accounts"
      class="edit-page-table"
      scrollHeight="flex"
    >
      <Column
        sortable
        body-style="padding-left: 6%;"
        field="dashboard_id"
        header="Dashboard ID"
        header-style="background: var(--white) !important; padding-left: 3%;"
      >
      </Column>
      <Column
        sortable
        body-style="text-align: center;"
        header="Name"
        field="name"
        header-style="background: var(--white) !important; text-align: center;"
      >
      </Column>
      <Column
        sortable
        body-style="text-align: center;"
        field="type"
        header="Type"
        header-style="background: var(--white) !important; text-align: center;"
      >
      </Column>
      <Column
        sortable
        body-style="text-align: center;"
        field="contract_status"
        header="Contract Status"
        header-style="background: var(--white) !important; text-align: center;"
      >
      </Column>
      <Column
        sortable
        body-style="text-align: center;"
        field="tier"
        header="Tier"
        header-style="background: var(--white) !important; text-align: center;"
      >
      </Column>
      <Column
        sortable
        body-style="text-align: center;"
        field="expiration_date"
        header="Expiration Date"
        header-style="background: var(--white) !important; text-align: center;"
      >
      </Column>

      <Column
        body-style="text-align: center; color: var(--primary-1);"
        header-style="background: var(--white) !important;"
      >
        <template #body="slotProps">
          <router-link :to="'/mastersheet/' + slotProps.data.id">
            <i class="pi pi-external-link" />
          </router-link>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { readCreatorsByManager, readManager } from "@/store/manager/getters";
import { api } from "@/api";

@Component
export default class AccountsManagedByManager extends Vue {
  @Prop() public readonly counts: any[];
  public accounts = [];

  get manager() {
    return readManager(this.$store);
  }

  public async mounted() {
    const managerId = parseInt(this.$route.params.id, 0);
    if (managerId) {
      const response = await api.listAccountsByManager(managerId);
      if (response) {
        this.accounts = response.data;
      }
    }
  }
}
</script>

<style scoped>
.CreatorsManagedByManager {
  border-left: solid 1px var(--cloudy-blue);
  padding-left: 2.8%;
  padding-right: 2.4%;
  height: 100%;
  width: 50%;
  position: absolute;
  right: 0;
  top: 95px;
}
</style>
