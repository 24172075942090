<template>
  <div class="p-d-flex Manager">
    <ManagerEdit ref="managerEdit"></ManagerEdit>
    <div id="right-edit-block">
      <b-tabs
        active-nav-item-class="manage-edit-tab-active"
        align="right"
        class="manage-edit-tabs"
        content-class="mt-3"
      >
        <b-tab
          title="Accounts"
          title-link-class="manage-edit-tab right-most-tab"
        >
          <AccountsManagedByManager></AccountsManagedByManager>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { readManager } from "@/store/manager/getters";
import ManagerEdit from "./Manager/ManagerEdit.vue";
import AccountsManagedByManager from "./Manager/ManagerEdit/AccountsManaged.vue";
import { dispatchGetManager } from "@/store/manager/actions";
import { dispatchListOffices, dispatchListTeams } from "@/store/main/actions";

@Component({
  components: {
    ManagerEdit,
    AccountsManagedByManager,
  },
})
export default class Manager extends Vue {
  public counts = [];

  get manager() {
    return readManager(this.$store);
  }

  public async mounted() {
    const managerId = parseInt(this.$route.params.id, 0);
    window["analytics"]["page"](`/manage/managers/${managerId}`);
    await dispatchGetManager(this.$store, { id: managerId });
    this.$refs.managerEdit["reset"]();
    await dispatchListOffices(this.$store);
    await dispatchListTeams(this.$store);
  }

  beforeRouteLeave(to, from, next) {
    if (this.$refs.managerEdit["edited"]()) {
      if (!window.confirm("Leave without saving?")) {
        return;
      }
    }
    next();
  }

  public beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  }

  preventNav(event) {
    if (!this.$refs.managerEdit["edited"]()) {
      return;
    }
    event.preventDefault();
    event.returnValue = "";
  }
}
</script>

<style>
.Manager {
  background-color: var(--white);
  height: 100%;
  overflow: hidden;
  position: relative;
}

#right-edit-block {
  height: 100%;
  width: 50%;
}
</style>
